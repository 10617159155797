<template>
<div style="display: flex; justify-content: center; align-items: center; min-height: calc(100vh - 60px); padding: 30px;">
        <b-card style="width: 100%; height: 100%; max-width: 800px;">
            <div class="text-center mb-2">
            <b-img :src="require('@/assets/img/island-candos-logo.png')" fluid height="85"></b-img>
        </div>
        <hr />
            <h3  class="text-center">{{ $t('Room Change Request Form') }}</h3>
            <b-form>
            <b-row>
                <b-col sm="6" md="6" lg="6" xl="6" class="mb-3">
                    <label><b>{{ $t('full_name') }}</b></label>
                    <b-form-input  placeholder="Username"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label><b>{{ $t('date') }}</b></label>
                    <select-date></select-date>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6" class="mb-3">
                    <label><b>{{ $t('current_sem_level') }}</b></label>
                    <b-form-input placeholder="Level"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label><b>{{ $t('email') }}</b></label>
                    <b-form-input placeholder="Email"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6" class="mb-3">
                    <label><b>{{ $t('building_room') }}</b></label>
                    <b-form-input placeholder="Room"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label><b>{{ $t('phone_number') }}</b></label>
                    <b-input-group>
                        <b-form-input placeholder="Phone Number"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" v-b-modal.modal-1> <b-icon-pencil></b-icon-pencil></b-button>
                          <b-modal id="modal-1" hide-footer :title="$t('edit')" centered>
                            <ValidationProvider name="mobile_tel_country_code" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('country')">
                                <multiselect
                                    v-model="formData.mobile_tel_country_code"
                                    :options="countryCodeData"
                                    track-by="text"
                                    label="text"
                                    @input="selectedCountryCode"
                                    :searchable="true">
                                </multiselect>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="mobile_tel" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('mobile_number')">
                                <b-form-input
                                    v-mask="mask"
                                    v-model="formData.mobile_tel" :class="errors[0] ? 'is-invalid' : ''">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <div class="d-flex justify-content-center">
                        <b-button class="mt-3" @click="$bvModal.hide('modal-1')">{{ $t('save') }}</b-button>
                    </div>
                          </b-modal>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <p><b>{{ $t('reason_for_request') }}</b></p>
            <b-form-textarea
                id="textarea"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
               class="mb-3"
            ></b-form-textarea>
            <p><b>IMPORTANT: </b><u>Completing this form and turning it in does not automatically guarantee a room change.</u> All room changes are subject to room availability. If approved,
                you will be given instructions for moving. Changing rooms without expressed, written permission will result in forfeiture of security deposit and reassignment.</p>
                <b-row>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label><b>{{ $t('resident_signature') }}</b></label>
                    <b-form-input placeholder="Resident Signature"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6" class="mb-3">
                    <label><b>{{ $t('date') }}</b></label>
                    <select-date></select-date>
                </b-col>
            </b-row>
        <hr />
            <p><i><u>{{ $t('internal_use_only') }}</u></i></p>
            <label>{{ $t('reviewed_by') }}</label>
            <b-form-input placeholder="Reviewed by" class="mb-3"></b-form-input>
            <b-form-checkbox-group class="mb-3">
                <b-form-checkbox value="approved">{{ $t('approved') }}</b-form-checkbox>
                <b-form-checkbox value="denied">{{ $t('denied') }}</b-form-checkbox>
            </b-form-checkbox-group>
            <label>{{ $t('reason_for_denial') }}</label>
            <b-form-input placeholder="Reason for denial" class="mb-3"></b-form-input>
            <b-row>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label>{{ $t('signature') }}</label>
                    <b-form-input placeholder="Signature"></b-form-input>
                </b-col>
                <b-col sm="6" md="6" lg="6" xl="6">
                    <label>{{ $t('date') }}</label>
                    <select-date></select-date>
                </b-col>
            </b-row>
        </b-form>
        <div class="d-flex justify-content-end mt-5">
        <b-button>{{ $t('send') }}</b-button>
    </div>
        </b-card>
    </div>
  </template>

  <script>
import handleInput from '@/helpers/handleInput';
import countryCodeInputData from "./countryCodeInputData"
import { ValidationProvider } from 'vee-validate'


export default {
    components: {
        ValidationProvider,

    },
    data() {
        return {
            mask: '',
            formId: null,
            formData: {},
            countryCodeInputData
        }
    },
    computed: {
        countryCodeData() {
            let countryCode = []
            this.countryCodeInputData.forEach(itm => {
                countryCode.push({
                    text: itm.text,
                    value: itm.value,
                    code: itm.maxlength ?? null
                })
            })
            return countryCode
        }
    },
    methods: {
        handleInput,
        selectedCountryCode(value) {
            const find = this.countryCodeInputData.filter(a => a.value == value.value)
            if (!find) {
                return;
            }
            const maxPhoneNumberLength = parseInt(find[0].maxlength)
            this.mask = `${value.value} ${'#'.repeat(maxPhoneNumberLength)}`;
        },
        getCountryName(value) {
            const find = this.countryCodeInputData.find(a => a.value == value)
            if (find) {
                return find.text
            }
            return ""
        },
        async getData() {
            this.formData = await {
                personal_email: this.studentProgram.personal_email,
                mobile_tel: this.studentProgram.mobile_tel,
                home_tel: this.studentProgram.home_tel,
                emergency_person_relation: this.studentProgram.emergency_person_relation,
                emergency_person_name: this.studentProgram.emergency_person_name,
                emergency_person_phone: this.studentProgram.emergency_person_phone,
            }
            if (this.studentProgram.mobile_tel_country_code != null) {
                this.selectedCountryCode(this.studentProgram.mobile_tel_country_code)
                this.formData.mobile_tel_country_code =
                    {
                        value: this.studentProgram.mobile_tel_country_code,
                        text: this.getCountryName(this.studentProgram.mobile_tel_country_code)
                    }
            } else {
                this.mask = '(+1) ###-####'
                this.formData.mobile_tel_country_code = null
            }

        },
    }
}


</script>
